import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const InviteRedirect = () => {
  const router = useRouter()
  useEffect(() => {
    router.replace('/')
  }, [router])
  return null
}
export default InviteRedirect
